<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CAlert
                  :color="alertColor"
                  closeButton
                  :show.sync="showAlert"
                >
                  {{ alertContent }}
                </CAlert>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="Username"
                    autocomplete="username"
                    v-model="username"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton color="primary" class="px-4"
                        @click="login"
                        >
                        Login
                      </CButton>
                      <div
                        class="spinner-border spinner-border-sm login-spinner"
                        role="status"
                        :hidden="!loading"
                        >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import AxiosBase from '../../api/AxiosBase'

export default {
  name: 'Login',
  created() { 
  },
  data () {
    return {
      loading: false,
      username: "",
      password: "",

      alertColor: 'success',
      showAlert: false,
      alertContent: "",
    }
  },
  methods: {
    login() {
      var _this = this;
      _this.loading = true;

      AxiosBase.post("/auth/login", {
        username: _this.username,
        password: _this.password
      })
      .catch(function(error) {
        console.log(error);

        _this.alertColor = "danger";
        _this.showAlert = true;
        _this.alertContent = "Login failed. Check console for detail."
        _this.loading = false;
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        var resValue = res?.data?.value;

        if (resCode != null && resCode >= 0) {
          _this.alertColor = "success";
          _this.showAlert = true;
          _this.alertContent = `Login successfully.`;

          localStorage.setItem('username', _this.username);
          localStorage.setItem('jwt', resValue.access_token);

          _this.$router.push('/profiles');
        } else {
          // show errors
          _this.alertColor = "danger";
          _this.showAlert = true;
          _this.alertContent = `Login failed. Code: ${resCode}. Reason: ${res?.data?.ext?.reason}`

          console.log("errors: " + JSON.stringify(res));
        }
        _this.loading = false;
      });
    }
  }
}
</script>

<style>
.login-spinner {
  margin: 0 8px;
}
</style>
